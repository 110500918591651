let BASE_SERVER_URL;
if (process.env.REACT_APP_ENV === 'stage') {
    BASE_SERVER_URL = 'https://qcws-back-stage.attentive.ai';
} else if (process.env.REACT_APP_ENV === 'dev') {
    BASE_SERVER_URL = 'https://dev.qcws-back.attentive.ai';
} else if (process.env.REACT_APP_ENV === 'qa') {
    BASE_SERVER_URL = 'https://qa.qcws-back.attentive.ai';
} else if (process.env.REACT_APP_ENV === 'prod') {
    BASE_SERVER_URL = 'https://qcws-back.attentive.ai';
} else {
    // BASE_SERVER_URL = 'https://dev.qcws-back.attentive.ai';
    BASE_SERVER_URL = 'https://qcws-back-stage.attentive.ai';
}
export const BASE_SERVER = BASE_SERVER_URL;

let url = '';
if (process.env.REACT_APP_ENV === 'stage') {
    url = 'https://annotations-stage.attentive.ai/';
} else if (process.env.REACT_APP_ENV === 'dev') {
    url = 'https://dev.annotations.attentive.ai/';
} else if (process.env.REACT_APP_ENV === 'qa') {
    url = 'https://qa.annotations.attentive.ai/';
} else if (process.env.REACT_APP_ENV === 'prod') {
    url = 'https://annotations.attentive.ai/';
} else {
    url = 'http://localhost:3001/';
}

export const ANNOTATIONS_URL = url;
/**
 * Annotation Page
 */
export const BASE_URL = BASE_SERVER + '/api/';
export const LOGIN = 'auth/login/';
export const GET_JOB = 'active_work_items/';
export const PING = 'ping/';
export const SUBMIT_JOB = 'active_work_items/%s/submit/';
export const SAVE_JOB = 'active_work_items/%s/save/';
export const UPLOAD_FEEDBACK_FILE = 'active_work_items/%s/feedback/';
export const UPDATE_META_DATA = 'active_work_items/%s/set_meta_data/';
export const GET_LEADERBOARD = 'ratings/leaderboard/';
export const EXTENSION_TIMER = 'active_work_items/%s/extend_time/';
export const TAKE_BREAK = 'users/job_break/';
export const HOLD_ONLINE_FLOW = 'service_name/';
export const HANDLE_OFFLINE_REQUEST = 'users/%s/offline_request/';
export const QUICK_FLOW = 'active_work_items/%s/quick_flow/';
/**
 * Rating Pages
 */
export const GET_LAYERS = 'layer_types/';
export const GET_QC_RATINGS = 'ratings/qc/';
export const GET_QA_RATINGS = 'ratings/qa/';
export const GET_QA_RATINGS_FOR_ADMIN = 'falcon/qa_ratings/';
export const RATE_QA = 'ratings/rate_qa/';

export const ADD_RATING = 'active_work_items/%s/add_rating/'
/**
 * Jobs
 */
export const GET_JOBS = 'monitoring/job_groups/?source_type=';
export const GET_SOURCES = 'monitoring/source_type/';
export const GET_USERS = 'monitoring/users/all/?source_type=';
export const GET_MONITORING_USERS = 'monitoring/users/?source_type=';
export const GET_REQUESTS = 'monitoring/requests/?source_type=';
export const RESET_JOBS = 'jobs/%s/reset_jobgroup/';

/**
 * Management
 */
export const LIST_USERS = 'users/';
export const CREATE_USER = 'auth/create_user/';
export const DISABLE_USER = 'auth/disable/';
export const ENABLE_USER = 'auth/enable/';
export const SOURCE_TYPE = 'monitoring/source_type/';
export const SOURCE_TYPE_CONFIG = 'configurations/';
export const SOURCE_TYPE_SINGLE = 'monitoring/source_type/%s/';
export const LAYER_TYPE = 'monitoring/layer_type/';
export const LAYER_TYPE_SINGLE = 'monitoring/layer_type/%s/';
export const LAYER_GROUP = 'monitoring/layer_group/';
export const LAYER_GROUP_SINGLE = 'monitoring/layer_group/%s/';
export const TEAM = 'monitoring/team/';
export const TEAM_SINGLE = 'monitoring/team/%s/';
export const UPDATE_REQUEST_PRIORITY = 'requests/%s/';
export const BULK_ADD_USERS = 'users/bulk_add_users/';
export const VISUALIZE_OUTPUT = 'monitoring/job_groups/%s/visual';
export const ALLOCATE_JOB = 'jobs/%s/allocate_job/';
export const EXCHANGE_JOB = 'jobs/%s/exchange_job/';
export const FREE_USER = 'users/free_user/';

// falcon data platform
// export const FALCON_REQUESTS = 'falcon/requests/';
export const FALCON_REQUESTS = 'wrapper?type=Falcon';
export const FALCON_REQUEST_DETAILS = 'wrapper/%s?type=Falcon';
export const FALCON_UPLOAD = 'wrapper/%s/upload?type=Falcon';
export const FALCON_DOWNLOAD = 'wrapper/%s/download?type=Falcon';
export const FALCON_ETA_UPDATE = 'wrapper/%s/edit_eta?type=Falcon';

// data platform
// export const REQUESTS = 'autobid/requests/';
export const REQUESTS = 'wrapper?type=Autobid';
export const REQUEST_DETAILS = 'wrapper/%s?type=Autobid';
export const UPLOAD = 'wrapper/%s/upload?type=Autobid';
export const DOWNLOAD = 'wrapper/%s/download?type=Autobid';
export const ETA_UPDATE = 'wrapper/%s/edit_eta?type=Autobid';

/**
 * Upload Platform
 */
export const DUPLICATE_SHEETS = 'blueprint/%s/duplicate_sheet/';
export const GET_TAKEOFF_REQUESTS = 'blueprint/requests/';
export const UPLOAD_PRICE_TAT = 'blueprint/%s/tat/';
export const SEND_TAKEOFF_EMAIL = 'blueprint/%s/email/';
export const SEND_TAKEOF_OUTPUT = 'falcon/%s/upload_output/';
export const UPLOAD_WORKSHEETS = 'blueprint/%s/upload/';
export const GET_FEATURES = 'blueprint/%s/features/';
export const GET_SHEETS = 'blueprint/%s/sheets/';
export const GET_FILES = 'blueprint/%s/blueprint_files/';
export const ADDENDUM_MAPPING = 'blueprint/%s/addendum_mapping/';
export const GET_SELECTED_SHEETS = 'blueprint/%s/worksheets/';
export const ADD_FEATURE = 'blueprint/%s/add_feature/';
export const REMOVE_FEATURE = 'blueprint/%s/remove_feature/';
export const DOWNLOAD_FEATURE = 'blueprint/%s/download_features/';
export const ATTACH_FEATURE = 'blueprint/%s/attach_features/';
export const SUBMIT_TAKEOFF = 'blueprint/%s/submit/';
export const GET_CATEGORIES = 'blueprint/categories/';
export const EDIT_FEATURES = 'blueprint/%s/edit_feature/';
export const EDIT_ASSEMBLY_MATERIALS = 'blueprint/%s/update_assembly_materials/';

/**
 * Upload Platform AutoBid
 */
export const GET_TAKEOFF_REQUESTS_AUTOBID = 'autobid/blueprint/requests/';
export const DUPLICATE_SHEETS_AUTOBID = 'autobid/blueprint/%s/duplicate_sheet/';
export const UPLOAD_PRICE_TAT_AUTOBID = 'autobid/blueprint/%s/eta/';
export const SEND_TAKEOFF_EMAIL_AUTOBID = 'autobid/blueprint/%s/send_email/';
export const UPLOAD_WORKSHEETS_AUTOBID = 'autobid/blueprint/%s/upload/';
export const GET_FEATURES_AUTOBID = 'autobid/blueprint/%s/features/';
export const GET_SHEETS_AUTOBID = 'autobid/blueprint/%s/sheets/';
export const GET_SELECTED_SHEETS_AUTOBID = 'autobid/blueprint/%s/worksheets/';
export const ADD_FEATURE_AUTOBID = 'autobid/blueprint/%s/features/';
export const REMOVE_FEATURE_AUTOBID = 'autobid/blueprint/%s/remove_feature/';
export const DOWNLOAD_FEATURE_AUTOBID = 'autobid/blueprint/%s/download_features/';
export const ATTACH_FEATURE_AUTOBID = 'autobid/blueprint/%s/attach_features/';
export const SUBMIT_TAKEOFF_AUTOBID = 'autobid/blueprint/%s/submit/';
export const GET_CATEGORIES_AUTOBID = 'autobid/blueprint/categories/';
export const AUTOBID_CATALOGS = 'autobid/blueprint/%s/catalogs/';
export const GET_MEASUREMENT_TYPES = 'blueprint/measurement_types/';
export const TEXT_DETECTION_API = 'requests/extract_text_from_image/';

/**

 * Idle Time
 */

export const IDLE_BREAK = 'active_work_items/%s/end_idle_time';
export const IDLE_START = 'active_work_items/%s/start_idle_time';

/**

 * Comments
 */
export const ADD_COMMENT = 'comments/';
export const UPDATE_COMMENT = 'comments/%s/';
export const DELETE_COMMENT = 'comments/%s/';
export const RESOLVE_COMMENT = 'comments/%s/resolve_comment/';

